var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12",staticStyle:{"overflow":"auto","white-space":"nowrap"}},[_c('b-table',{attrs:{"items":_vm.pendonorData,"per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"responsive":"sm","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(aksi)",fn:function(row){return _vm._l((_vm.navigate),function(item,index){return _c('b-button-group',{key:index},[_c('router-link',{attrs:{"to":`${item.to}/${row.item.id}`}},[_c('b-button',{staticClass:"mx-1",attrs:{"size":"sm","variant":item.variant}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(item.name)+" ")],1)],1)],1)})}},{key:"cell(status)",fn:function(row){return [_c('b-badge',{attrs:{"variant":row.item.status === 'Approved'
            ? 'success'
            : row.item.status === 'Rejected'
            ? 'danger'
            : row.item.status === 'Done'
            ? 'primary'
            : row.item.status === 'Waiting'
            ? 'warning'
            : 'danger'}},[_vm._v(" "+_vm._s(row.item.status === "Approved" ? "Terima" : row.item.status === "Rejected" ? "Tolak" : row.item.status === "Done" ? "Selesai" : row.item.status === "Waiting" ? "Proses" : "Tolak")+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"hp-p1-body text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-8"}),_c('strong',[_vm._v("Memuat...")])],1)]},proxy:true},{key:"table-empty",fn:function(){return [_c('div',{staticClass:"hp-p1-body text-center my-2"},[_c('strong',[_vm._v("Data tidak ditemukan")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }